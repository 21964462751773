<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 14:11:20
 * @Description: 
-->
<template>
  <div>
    <Head></Head>
    <!-- {{category}} -->
    <div class="content">
      <div class="wrapper">
        <div class="title">
          {{ category.name }}
        </div>
        <div class="articleList" v-if="articleListData.total > 0">
          <div
            class="art"
            v-for="articleItem in articleListData.list"
            :key="articleItem.id" @click="ToArticle(articleItem)"
          >
            <div class="logo">
              <img :src="articleItem.cover" alt="" />
            </div>
            <div class="detailmes">
              <div class="title">
                {{ articleItem.title }}
              </div>
              <div class="pubTime">
                发布时间：{{ articleItem.publishTime | fmtDate }}
              </div>
              <div class="readTime">阅读次数：{{ articleItem.readTimes }}</div>
            </div>
          </div>
          <div class="page">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="param.page"
              :page-size="param.pageSize"
              layout="total, prev, pager, next"
              :total="articleListData.total"
            >
            </el-pagination>
          </div>
        </div>
        <div v-else class="nodata">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
import { get } from "../utils/request";
export default {
  components: {
    // 1.引入所需组件

    // 2.注册组件
    Head,
  },
  data() {
    return {
      category: {},
      // 分页参数
      param: {
        page: 1,
        pageSize: 4,
      },
      articleListData: {},
    };
  },
  methods: {
    getArticleData() {
      // console.log(this.param);
      this.param.categoryId = this.category.id;
      get("/index/article/pageQuery", this.param).then((res) => {
        // console.log(res);
        this.articleListData = res.data;
      });
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);

      // 使用当前页数与我们分页控制的参数进行赋值
      this.param.page = val;
      this.getArticleData();
    },
    ToArticle(articleItem){
      this.$router.push({
        path:'/Article',
        query:articleItem
      })
      
    }
  },
  // 侦听器
  watch: {
    "$route.query": {
      handler(query) {
        // alert(JSON.stringify(query))
        this.category = query;
        // 每次点击菜单栏将页数重置为1
        this.param.page = 1;
        this.getArticleData();
      },
      // 开启深度侦听
      deep: true,
    },
  },
  created() {
    this.category = this.$route.query;
    this.getArticleData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #ecf6f2;
  min-height: 800px;
  padding: 10px;
  .wrapper {
    height: 400px;
    padding: 10px;

    .title {
      font-size: 35px;
      font-weight: bold;
      color: #528c38;
      // background-color: #fff;
      padding: 10px;
    }
    .articleList {
      min-height: 300px;
      background-color: #fff;
      padding: 10px;

      .art {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        display: flex;
        .logo {
          flex: 1;
          img {
            width: 200px;
            height: 150px;
          }
        }
      }
      .detailmes {
        flex: 2;
        .title {
          padding: 0px;
          font-weight: 30px;
          color: black;
          cursor: pointer;
          margin-bottom: 15px;
        }
        .pubTime {
          margin-bottom: 15px;
        }
      }
    }
    .nodata{
      background-color: #fff;
      min-height: 300px;
    }
  }
}
</style>